import React, { useState } from "react";
import { apiCall } from "../utils/api";

const ManageSubscriptionButton = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleManageSubscription = async () => {
    setLoading(true);
    try {
      const response = await apiCall(
        "/create-customer-portal-session",
        "POST",
        {}
      );
      window.location.href = response.url;
    } catch (error) {
      console.error("Error redirecting to customer portal:", error);
      setError(
        "There was an issue managing your subscription. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <button onClick={handleManageSubscription} disabled={loading}>
        {loading ? "Loading..." : "Manage Subscription"}
      </button>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default ManageSubscriptionButton;
