import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import SubscribeLink from "./SubscribeLink";

const Header = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const profile = JSON.parse(localStorage.getItem("profile") || "{}");
  const subscription = localStorage.getItem("subscription") || "Trial";
  const showSubscriptionLink =
    subscription === "Trial" || subscription === "cancelled";
  const profilePicture = profile?.picture || null;
  const userName = profile?.name || "Account";

  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="sticky-top">
      <Navbar.Brand href="/dashboard" className="ms-2 me-3">
        Story Starter
      </Navbar.Brand>
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text>
          {subscription} {showSubscriptionLink && <SubscribeLink />}
        </Navbar.Text>
      </Navbar.Collapse>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        {isLoggedIn && (
          <Nav className="ms-auto">
            <NavDropdown
              title={
                <span>
                  {profilePicture ? (
                    <img
                      src={profilePicture}
                      alt="Profile"
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginRight: "10px",
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon icon={faUser} className="me-2" />
                  )}
                  {userName}
                </span>
              }
              id="account-dropdown"
              align="end"
            >
              <NavDropdown.Item as={Link} to="/manage-subscription">
                Manage Subscription
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/settings">
                Settings
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/logout">
                Log Out
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
