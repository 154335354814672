// src/components/LogOut.tsx
import React, { useEffect } from "react";
import { clearTokens, getLogOutUrl } from "../auth";

const LogOut = () => {
  useEffect(() => {
    // Clear local authentication tokens
    clearTokens();

    // TODO: Redirect to Cognito logout and then back to the root of the application
    const logoutUrl = getLogOutUrl();
    window.location.href = logoutUrl;
  }, []);

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <p>Logging you out...</p>
    </div>
  );
};

export default LogOut;
