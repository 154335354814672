import React from "react";
import { Alert } from "react-bootstrap";

interface ErrorAlertProps {
  errorMessages: string[];
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({ errorMessages }) => {
  if (errorMessages.length === 0) {
    return null;
  }

  // Cpecific error require a new login
  const invalidGrant = errorMessages.some(
    (message) =>
      message === "invalid_grant" ||
      message === "refresh_token_error" ||
      message === "no_access_token"
  );

  if (invalidGrant) {
    errorMessages = [];
  }

  return (
    <>
      {errorMessages.length > 0 && (
        <Alert
          variant="danger"
          onClose={() => {
            errorMessages = [];
          }}
          dismissible
        >
          <ul>
            {errorMessages.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        </Alert>
      )}
      {invalidGrant && (
        <Alert variant="danger">
          <p>
            Your session has expired. Please <a href="/logout">log out</a> and
            then login again...
          </p>
        </Alert>
      )}
    </>
  );
};

export default ErrorAlert;
