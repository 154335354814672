// Import necessary modules and components
import React, { Suspense, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";
import Placeholder from "./components/Placeholder";
import Callback from "./components/Callback";
import LogOut from "./components/LogOut";
import ProcessPrompt from "./components/ProcessPrompt";
import Expander from "./components/Expander";
import { Container, Row, Col } from "react-bootstrap";
import { getAuthUrl, getAccessToken } from "./auth";
import SubscriptionSuccess from "./components/SubscriptionSuccess";
import ManageSubscription from "./components/ManageSubscription";
import "./App.css";
import LogIn from "./components/LogIn";
import { apiCall } from "./utils/api";

// Lazy-loaded components
const Dashboard = React.lazy(() => import("./components/Dashboard"));
const Settings = React.lazy(() => import("./components/Settings"));
const ListSnippets = React.lazy(() => import("./components/ListSnippets"));
const ListBooks = React.lazy(() => import("./components/ListBooks"));
const EditSnippet = React.lazy(() => import("./components/EditSnippet"));
const EditBook = React.lazy(() => import("./components/EditBook"));

const App = () => {
  const location = useLocation();

  const handleLogin = async () => {
    const authUrl = await getAuthUrl();
    window.location.href = authUrl;
  };

  const isRootPath = location.pathname === "/";
  const isLoggedIn = Boolean(getAccessToken());

  // Check if the "login" or "signin" parameter is present in the URL
  const searchParams = new URLSearchParams(location.search);
  const showLoginButton =
    searchParams.has("login") ||
    searchParams.has("signin") ||
    window.location.href === "http://localhost:3000/";

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      // Store the original console.error function
      const originalConsoleError = console.error;

      // Override console.error
      console.error = function (message?: any, ...optionalParams: any[]) {
        // Call the original console.error
        originalConsoleError.apply(console, [message, ...optionalParams]);

        // Check if the user is logged in
        const maybeToken = getAccessToken();

        const payload = {
          message: message,
          optionalParams: optionalParams,
          userAgent: navigator.userAgent,
          url: window.location.href,
          maybeToken,
        };

        apiCall("/client-error", "POST", payload, false).catch((err) => {
          console.warn("Failed to send", err);
        });
      };

      // Clean up function to restore the original console.error when the component unmounts
      return () => {
        console.error = originalConsoleError;
      };
    }
  }, []);

  return (
    <>
      {isRootPath && !isLoggedIn ? (
        <div>
          <Placeholder />
          <p style={{ textAlign: "center", marginTop: "20px" }}>
            {showLoginButton ? "Log in to use the site." : ""}
          </p>
          {showLoginButton && (
            <div style={{ textAlign: "center" }}>
              <button onClick={handleLogin}>Log In</button>
            </div>
          )}
        </div>
      ) : (
        <div className="d-flex flex-column min-vh-100">
          <Header isLoggedIn={isLoggedIn} />
          <Container fluid className="main-row">
            <Row className="flex-grow-1">
              <Col md={2} className="bg-light p-3">
                <Sidebar />
              </Col>
              <Col md={10} className="p-3 main-content">
                <Suspense fallback={<div>Loading...</div>}>
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/process-prompt" element={<ProcessPrompt />} />
                    <Route path="/expander" element={<Expander />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/books" element={<ListBooks />} />
                    <Route path="/snippets" element={<ListSnippets />} />
                    <Route path="/edit-book" element={<EditBook />} />
                    <Route path="/edit-snippet" element={<EditSnippet />} />
                    <Route path="/callback" element={<Callback />} />
                    <Route path="/login" element={<LogIn />} />
                    <Route path="/logout" element={<LogOut />} />
                    <Route
                      path="/subscription-success"
                      element={<SubscriptionSuccess />}
                    />
                    <Route
                      path="/manage-subscription"
                      element={<ManageSubscription />}
                    />
                  </Routes>
                </Suspense>
              </Col>
            </Row>
          </Container>
          <Footer />
        </div>
      )}
    </>
  );
};

export default App;
