import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { exchangeCodeForTokens } from "../auth";

const Callback = () => {
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleCodeExchange = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");

      if (code) {
        try {
          await exchangeCodeForTokens(code);
          navigate("/dashboard");
        } catch (error) {
          setError("Error exchanging code for tokens. Please try again.");
          console.error(error);
        }
      } else {
        setError("No authorization code found in the URL.");
      }
    };

    handleCodeExchange();
  }, [navigate]);

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      {error ? (
        <p style={{ color: "red" }}>{error}</p>
      ) : (
        <p>Signing you in...</p>
      )}
    </div>
  );
};

export default Callback;
