import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { apiCall } from "../utils/api";
import Loader from "./Loader";

const SubscriptionSuccess = () => {
  const [loading, setLoading] = useState(true);
  const [subscribed, setSubscribed] = useState(false);
  const [ending, setending] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const pollUserSubscription = async () => {
      let attempts = 0;
      const maxAttempts = 20;
      const baseDelay = 1000; // Start with a 1-second delay

      while (attempts < maxAttempts) {
        try {
          const response = await apiCall("/get-user", "POST", {});

          const subscription = response.subscription ?? "";

          // TODO: Consider re-subscription, currently the id will be there from last time?
          if (response.hasSubscriptionId) {
            if (subscription === "monthly") {
              setSubscribed(true);
            } else if (subscription === "ending") {
              setending(true);
            }
            localStorage.setItem("subscription", subscription);
            setLoading(false);
            return;
          }

          attempts++;
          const delay = baseDelay + attempts * 100; // Increase delay with each attempt
          await new Promise((resolve) => setTimeout(resolve, delay));
        } catch (err) {
          console.error("Error polling for subscription:", err);
          setError(
            "There was an error checking your subscription. Please try again later."
          );
          setLoading(false);
          return;
        }
      }

      // If we reach here, it means polling timed out
      setError("Subscription process timed out. Please try again later.");
      setLoading(false);
    };

    pollUserSubscription();
  }, [navigate]);

  if (loading) {
    return (
      <div>
        <p>We are processing your subscription. Please wait...</p>
        <p>
          <Loader />
        </p>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div>
      {subscribed && (
        <p>
          Subscription successful! You now have access to premium features, go
          nuts.
        </p>
      )}
      {ending && (
        <p>
          Your subscription has been <em>cancelled</em>, your access to premium
          features will end soon.
        </p>
      )}
    </div>
  );
};

export default SubscriptionSuccess;
